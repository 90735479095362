import React, { useState, useEffect } from "react";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(true);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.pause();
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <button
      className="shadow-xl hover:shadow-2xl fixed bottom-0 left-0 z-50 bg-charleston-green text-neutral-1 font-bold h-12 w-12 flex justify-center items-center rounded-full m-4"
      onClick={toggle}
    >
      {!playing ? (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="#404040" />
          <path
            d="M25 14H23V16H21V18H19V20H15V28H19V30H21V32H23V34H25V14ZM21 30V28H19V26H17V22H19V20H21V18H23V30H21ZM31 23.223H29V21.223H27V23.223H29V25.223H27V27.223H29V25.223H31V27.223H33V25.223H31V23.223ZM31 23.223H33V21.223H31V23.223Z"
            className="fill-floral-white"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 2H13V22H11V20H9V18H11V6H9V4H11V2ZM7 8V6H9V8H7ZM7 16H3V8H7V10H5V14H7V16ZM7 16V18H9V16H7ZM17 10H15V14H17V10ZM19 8H21V16H19V8ZM19 16V18H15V16H19ZM19 6V8H15V6H19Z"
            className="fill-floral-white"
          />
        </svg>
      )}
    </button>
  );
};

export default Player;
